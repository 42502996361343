<template>
  <div>
    <div class="footbox">
      <div class="content">
        <div class="top">
          <div class="top-nav">
            <div class="navitem">
              <div class="navitem-title">
                {{ Language.foot.our_services }}
              </div>
              <div class="navitem-nav" v-for="(item, index) in nav" :key="index" @click="topath(item.url)">
                {{ item.title }}
              </div>
            </div>
            <div class="navitem">
              <div class="navitem-title">
                {{ Language.foot.service_products }}
              </div>
              <div class="navitem-nav" v-for="(item, index) in nav2" :key="index" @click="topath3(item.url,index)">
                {{ item.title }}
              </div>
            </div>
            <div class="navitem">
              <div class="navitem-title">
                {{ Language.foot.Our_testing_capabilities }}
              </div>
              <div class="navitem-nav" v-for="(item, index) in nav3" :key="index" @click="toknowledgeDetail(item)">
                {{ Language_type == 1 ? item.title:item.title_en }}
              </div>
            </div>
<!--            style="width: 108px; margin-top:27px"-->
            <div class="navitem" >
              <div class="navitem-title" style="opacity: 0;">
                {{ Language.foot.Our_testing_capabilities }}
              </div>
              <div class="navitem-nav" v-for="(item, index) in nav4" :key="index" @click="toknowledgeDetail(item)">
                {{ Language_type == 1 ? item.title:item.title_en }}
              </div>
            </div>
            <div class="navitem">
              <div class="navitem-title">
                {{ Language.foot.About_Us }}
              </div>
              <div class="navitem-nav" v-for="(item, index) in nav5" :key="index" @click="topath2(item.url,item.id)">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="top-dec">
            <div class="company">
              <div class="company-name">
                {{ Language.foot.company_name1 }}
              </div>
              <pre class="company-address noscrollbar">
ZHEJIANG QC+ TESTING TECHNOLOGY COLTD
地址:浙江省台州市临海市大田街道柏叶东路2508号7号楼
Add : Building 7, No. 2508, East Baiye Road,
Datian TownLinhai, Zhejiang, China
              </pre>
            </div>
            <div class="company">
              <div class="company-name">
                {{ Language.foot.company_name2 }}
              </div>
              <pre class="company-address noscrollbar">
ZINEW TESTING TECHNOLOGY (ZHEJIANG) CO,LTD
地址:浙江省杭州市萧山区经济技术开发区启迪路198号C幢1009-1室
Add : Room 1009-1, Building C,No.198 Qidi Road,
Economic and Technological DevelopmentZone,
Xiaoshan District, Hangzhou, Zhejiang, China
              </pre>
            </div>
            <div class="company">
              <div class="company-name">
                {{ Language.foot.company_name3 }}
              </div>
              <pre class="company-address noscrollbar">
ZINEW TESTING TECHNOLOGY (ZHEJIANG) CO,LTD
地址:宁波市鄞州区会展路128号017栋7-40-2
Add : 7-40-2, Building 017, No.128 Huizhan Road, Yinzhou District, Ningbo City
              </pre>
            </div>
            <pre class="contact noscrollbar">
Tel: +86-576-85900101/15888676999
Email: info@qua.link
Web: www.qua.link
            </pre>

            <div class="button" @click="tocontactUs">{{ Language.foot.Contact_Us }}</div>
          </div>
          <div class="top-qr">
            <img style="width: 150px;height: 150px" src="../assets/index/qr.jpg" alt="二维码" />
            <div class="share">
              <img src="../assets/index/share1.jpg" alt="链接" />
              <img src="../assets/index/share2.jpg" alt="链接"/>
              <img src="../assets/index/share3.jpg" alt="链接"/>
            </div>
          </div>
        </div>

        <div class="bottom" @click="tobeian" v-if="show == 1">
          © 2023 欧凯嵘创检测科技有限公司浙ICP备17020375号-1
        </div>
        <div class="bottom" @click="tobeian" v-if="show == 2">
          © 2023 欧凯嵘创检测科技有限公司浙ICP备2021005367号-1
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject:["reload","L"],
  metaInfo(){
    return{
      title:this.meta.url_title,
      meta:[
        {name:"title",content: this.meta.url_meta_title || '欧凯创荣'},
        {name:"keywords",content: this.meta.url_meta_keywords || '欧凯创荣'},
        {name:"description",content: this.meta.url_meta_desc || '欧凯创荣'}
      ]
    }
  },
  computed:{
    Language(){
      return this.L()
    }
  },
  data() {
    return {
      Language_type:"",
      meta:"",
      nav: "",
      nav2: "",
      nav3: [],
      nav4: [],
      nav5: "",

      show:1
    }
  },
  mounted() {
    if(window.location.href.indexOf("qc-plus.cn") != -1){
      this.show = 1
    }
     if(window.location.href.indexOf("qua.link") != -1){
       this.show = 2
     }

    this.Language_type = localStorage.getItem("Language_type")
    // console.log(this.Language_type)
    this.nav = [
      {
        title: this.Language.foot.certificate_services,
        url:'/certification'
      },
      {
        title: this.Language.foot.test_service,
        url:'/products'
      },
      {
        title: this.Language.foot.quality_control,
        url:'/our-services/quality-control-management'
      },
      {
        title: this.Language.foot.technical_advice,
        url:'/our-services/technical-consulting'
      },
      {
        title: this.Language.foot.E_commerce_services,
        url:'/our-services/e-commerce'
      }
    ]
    this.nav2 = [
      {
        title: this.Language.foot.Festival_gifts,
        url:'/products/christmas-lights-and-gifts'
      },
      {
        title: this.Language.foot.Infant_and_child_products_and_toys,
        url:'/products/baby-products-and-toys'
      },
      {
        title: this.Language.foot.textile,
        url:'/products/apparel-and-textiles'
      },
      {
        title: this.Language.foot.Outdoor_products,
        url:'/products/outdoor-goods'
      },
      {
        title: this.Language.foot.Furniture_teaching_aids,
        url:'/products/furniture'
      },
      {
        title: this.Language.foot.electrical_and_electronic_products,
        url:'/products/electronics'
      },
      {
        title: this.Language.foot.Food_contact_materials,
        url:'/products/food-contact-material'
      }
    ]
    this.nav5 = [
      {
        title: this.Language.foot.Company_Profile,
        url:'/about-us',
        id:'about1'
      },
      {
        title: this.Language.foot.Cooperation,
        url:'/about-us',
        id:'about2'
      },
      {
        title: this.Language.foot.Clients,
        url:'/about-us',
        id:'about3'
      }
    ]

    this.getdata()



    this.$get("https://qua.link/m/indexset/url_config",{
    }).then((res)=>{
      console.log(res)
      var flag = 0
      res.data.data.forEach((item, index)=>{
        console.log(item.url_path,this.$route.path)
        if(item.url_path == this.$route.path){
          flag = 1
          this.meta = item
        }
      })
      if(flag == 0){
        console.log(this.$route.meta.title)
        this.meta = {
          url_title:this.$route.meta.title,
          url_meta_title:this.$route.meta.title,
          url_meta_keywords:this.$route.meta.title,
          url_meta_desc:this.$route.meta.title,
        }
      }
    })



  },
  methods:{
    getdata(){
      this.$get("/m/indexset/knowledge", {
        tag:'laboratory',
        pageNo:-1
      }).then(res => {
        console.log(res)
        this.nav3 = res.data.data.slice(0,8)
        this.nav4 = res.data.data.slice(8,16)
      })
    },
    toknowledgeDetail(item){
      console.log(item)
        this.$router.push({
          path:"/testing/laboratory/" + item.url_key,
        })
        this.reload()
    },
    topath(url){
      this.$router.push(url)
    },
    topath2(url,id){
      console.log(this.$route)
      if(this.$route.name != 'aboutUs'){
        localStorage.setItem("LocalID",id)
        this.$router.push(url)
      }else {
        localStorage.setItem("LocalID",id)
        this.$emit('getLocalID')
      }
    },
    topath3(url,index){
      console.log(index + 1)
      if(this.$route.name != 'childProduct'){
        this.$router.push(url)
      }else {
        this.$emit("changetype",index + 1)
      }
    },
    tocontactUs(){
      this.$router.push("/contact")
    },
    tobeian(){
      window.open('https://beian.miit.gov.cn')
    },
  }
}
</script>

<style lang="scss" scoped>
.footbox {
  background-color: #1e2022;
  color: #999999;

  .content {
    margin: auto;
    width: 1200px;
    padding-top: 80px;

    .top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 40px;
      border-bottom: 1px solid #333333;

      .top-nav {
        width: 530px;
        display: flex;
        justify-content: space-between;

        .navitem {
          width: 84px;

          .navitem-title {
            color: #27BA69;
            font-weight: bold;
            padding-bottom: 8px;
            border-bottom: 2px solid #27BA69;
            font-size: 12px;
          }

          .navitem-nav {
            margin-top: 8px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }


      .top-dec {
        width: 361px;

        .company {
          .company-name {
            font-weight: bold;
            color: #FFFFFF;
            font-size: 14px;
          }

          .company-address {
            margin-top: 5px;
            font-size: 12px;

          }
        }
        .contact{
          color: #FFFFFF;
          font-size: 14px;
        }
        .button{
          color: #27BA69;
          border-radius: 100px;
          border: 1px solid #27BA69;
          width: 160px;
          text-align: center;
          line-height: 40px;
          font-size: 12px;
          cursor: pointer;
        }
        .button:hover{
          background-color: #00ad60;
          color: #fff;
        }

      }

      .top-qr{
        .share{
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          img{
            width: 40px;
            height: 40px;
            border-radius: 5px;
          }
        }
      }

    }

    .bottom{
      padding: 20px 0;
      color: #999999;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

@media (min-width: 1000px) and (max-width: 1200px){
  .footbox{
    .content{
      width: calc(100% - 20px);
    }
  }
}
@media (min-width: 800px) and (max-width: 1000px){
  .footbox{
    .content{
      width: calc(100% - 20px);
      .top{
        flex-wrap: wrap;
        .top-nav{
          width: 60%;
          .navitem{
            .navitem-title{
              font-size: 12px;
            }
          }
        }
        .top-dec{
          width: 40%;
        }
        .top-qr{
          margin-top: 20px;
          width: 100%;
          text-align: center;
          .share{
            opacity: 0;
            height: 0;
            margin: 0;
          }
        }
      }
    }

  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .footbox{
    .content{
      width: calc(100% - 20px);
      .top{
        flex-wrap: wrap;
        .top-nav{
          width: 100%;
          .navitem{
            .navitem-title{
              font-size: 12px;
            }
          }
        }
        .top-dec{
          width: 100%;
          margin-top: 30px;
          text-align: center;
          .button{
            margin: auto;
          }
        }
        .top-qr{
          margin-top: 20px;
          width: 100%;
          text-align: center;
          .share{
            opacity: 0;
            height: 0;
            margin: 0;
          }
        }
      }
    }

  }
}
@media (min-width: 0px) and (max-width: 500px) {
  .footbox{
    .content{
      width: calc(100% - 20px);
      .top{
        flex-wrap: wrap;
        .top-nav{
          width: 100%;
          flex-wrap: wrap;
          padding: 0 50px;
          .navitem{
            width: calc(43%);
            margin-bottom: 20px;
            .navitem-title{
              font-size: 12px;
            }
          }
        }
        .top-dec{
          width: 100%;
          margin-top: 30px;
          text-align: center;
          .button{
            margin: auto;
          }
        }
        .top-qr{
          margin-top: 20px;
          width: 100%;
          text-align: center;
          .share{
            opacity: 0;
            height: 0;
            margin: 0;
          }
        }
      }
    }

  }
}
</style>